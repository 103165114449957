.show_span {
  margin-left: 10px;
}
.show_span_edit {
  margin-left: 17px;
}
.my_reply_select_tag {
  height: 54px;
  border-radius: 28px;
}
.my_input_font_size {
  font-size: 14px !important;
}
.my_enqiry_form_text_area {
  border-radius: 28px;
}
.myMainDrawerClass {
  display: flex !important;
  width: 100% !important;
  justify-content: space-between !important;
  padding: 25px ;
}
.my_delete_popup_paragraph {
  padding-inline: 30px !important;
}
.myDialogPopUp {
  border-radius: 10px;
  width: 40% !important;
}
.table-bordered>:not(caption)>*>* {
  border-width:1px !important;
}
@media (max-width: 768px) {
  .myDialogPopUp {
    width: 70%;
  }
}
