


  .modal_container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 70%;
    background-color: whitesmoke;
    box-shadow: 24px;
    padding: 4px;
  }
  
  .image_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .cropper {
    background-color: red;
  }
  
  .slider {
    height: 10%;
  }
  
  .slider_container {
    background: white;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .disable_cropper {
    display: none;
  }
  
  .button_container {
    position: absolute;
  }
  